
import React from 'react'
import './Navbar.css'
import Programs from '../Programs/Programs'
import Cyprinciple from '../Cyprinciple/Cyprinciple'
import Title from '../Title/Title'

const Navbar = ()=> {
 
      return (
     <div className='contain'>
     <Title title =' Cyber Security'/>
     <Cyprinciple/>
     <Title subtitle='Our Services' title ='What We Offer'/>
     <Programs/>
     
     
      </div>
   );  
  
}

export default Navbar





