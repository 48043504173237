import React from 'react'
import Slider from 'react-slick'
import './Cyprinciple.css'

const Cyprinciple = () => {
  const settings = {
    infinite: true, // Infinite loop of images
    arrows:false,
    speed: 500, // Transition speed
    slidesToShow: 3, // Show one slide at a time
    slidesToScroll: 3, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Speed of autoplay (ms)
    responsive:[{breakpoint:780, 
    settings: {arrows: false, dots: false,  },},],
  };
 
  return (
    <div className="principle contain">
        <Slider {...settings}>
        <div className="principle1">
        <h1>Train Your Cyber Security Profesionals</h1>
         </div>
         <div className="principle2">
         <h1>Establish,Implement and Maintain Strong Cyber Security Governance and Management System</h1>
           </div>
        <div className="principle3">
          <h1>Invest For Technologies Only When Your Cyber Security Risks Tell You To Do So</h1>
         </div>
          <div className="principle1">
          <h1>Assess Your Cyber Security Risks Periodically</h1>
           </div>
           <div className="principle2">
           <h1> Help Your Employees Understand Their Roles In Securing Your Organization</h1> 
           </div>
           <div className="principle3">
          <h1>Invest For Technologies Only When Your Cyber Security Risks Tell You To Do So</h1>
         </div>
           
      </Slider>
      </div>
    
  );
};

export default Cyprinciple;








