import React from 'react';
import './Programs.css'
import Assess from '../../Assets/Assessment.png';
import Compliance from '../../Assets/Compliance.png';
import training from '../../Assets/training1.png';

const Programs = ({ title, description }) => {
  return (
    <div className="programs contain">
     {/* <div className="card">
       <div className='Maintitl'>
         <div className='top-title'>
          <h1>Consultancy</h1> 
          </div>
          </div>
     <img src={image1} alt={title} className="card-image" />
      <div className="card-content">
         <p > {description} In today’s rapidly evolving digital landscape, safeguarding your organization from cyber threats is more critical than ever. 
          At GcyTech, we provide comprehensive cybersecurity consultancy services designed to protect your business from cyber risks,
           ensure compliance, and enhance your overall security posture. Our team of seasoned experts brings deep industry knowledge, 
          cutting-edge tools, and proven methodologies to deliver customized solutions that meet your unique needs.</p>
        <button className='btne'> Read More</button>
      </div>
      </div>*/}
      <div className="card">
      <div className='Maintitl'><div className='top-title'><h1>Training and Awareness </h1> </div></div>
      <img src={training} alt={title} className="card-image" />
      <div className="card-content">
        {/*<h2 className="card-title">Title</h2>*/}
        <p>We design and implment cyber security programs to aware employees, customers, stakeholders, or individuals about cybersecurity risks, best practices, and how to protect sensitive data from internal and external cyber threats.</p>
        <button className='btne'><a href='https://gcytech.com/Training'>Read More</a> </button>
      </div>
      </div>
      <div className="card">
      <div className='Maintitl'>
      <div className='top-title'><h1>Security Assessment</h1> </div></div>
      <img src= {Assess} alt={title} className="card-image " />
      <div className="card-content">
      <p> We help organizations by simulating real world attack to identify weaknesses in systems, networks, or applications that may be exploited by attackers. 
        </p>
      <button className='btne'><a href='https://gcytech.com/Assessment'>Read More</a> </button>
      </div>
    </div>
    <div className="card"><div className='Maintitl'>
    <div className='top-title'><h1>Compliance Assessments</h1> </div></div>
      <img src={Compliance} alt={title} className="card-image" />
      <div className="card-content">
        {/*<h2 className="card-title">{title}</h2>*/}
        <p className="card-text">{description}We help organizations mandated to comply with international standards such as SWIFT, ISO 27001, PCIS DSS and others.</p>
        <button className='btne'><a href='https://gcytech.com/Compliance'> Read More</a></button>
      </div>
      </div>
     </div>
  );
};

export default Programs;


