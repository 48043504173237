import React from 'react'
import Title from '../Title/Title'
import Trainings from '../../Assets/trainig.png'
import   './Training.css'
import Cyprinciple
 from '../Cyprinciple/Cyprinciple'
const Training =()=> {

    return (
      <div className='contain'>            
     <Title subtitle='Our Service  ' title ='Training and Awareness'/>
     <div className='training contain '>    
         <div className='training-col'>
            <h3>Awareness Programs </h3>
            <p> We design and implment cyber security programs to aware employees, customers, stakeholders, or individuals about cybersecurity risks, best practices, and how to protect sensitive data from internal and external cyber threats.
               With the increasing number of cyberattacks targeting critical organizations globally, such training programs are critical to ensuring both individual and organizational safety in the digital world.
              We have different awareness modules for different audiences. </p>
              <h3>Standard Trainings</h3>
              <p> We offer different standard trainings such as ISO27001 lead auditor and implementor, CEH, CISSP, CISSM and others. . 
                 </p>
             </div>
              <div><img src={Trainings} className="card-image" /></div>             
      </div> 
       <div className='gaping'>
      <Cyprinciple/>
      </div>
      </div>
  )
}

export default Training 
