import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faLinkedin, faTwitter, faTelegram} from '@fortawesome/free-brands-svg-icons';
import Title from '../Title/Title'
import Locate from '../../Assets/location.png'
import   './Contact.css'
import Cyprinciple from '../Cyprinciple/Cyprinciple';

const Contact =()=> {

    return (
      <div className='contain'>            
       <Title subtitle='Feel free to contact us ' title ='we are here'/>
        <div className='contact contain '>    
         <div className='contact-col'>
            
            <ul>
            <h3>Please do not hesitate to meet us at: </h3>
            <li><FontAwesomeIcon icon={faMapMarkerAlt}/> Addis Ababa, Gulele</li>
              <h3>You may call us on: </h3>
              <li><i className="material-icons">phone</i> +251927160562 </li>
              <h3>You can email us via: </h3>
              <li> <FontAwesomeIcon icon={faEnvelope}/> gcytech@gcytech.com</li>
              </ul>
              <h3>Our Social Media  Adresses: </h3>
              <ul className='social-con'>
             
              <li><a href="#!"> <FontAwesomeIcon icon={faFacebook}/> </a></li>
              <li><a href="#!"> <FontAwesomeIcon icon={faTwitter}/> </a></li>
              <li><a href="#!"><FontAwesomeIcon icon={faTelegram}/></a></li>
              <li><a href="#!"><FontAwesomeIcon icon={faLinkedin}/> </a></li>
              </ul>
              </div>
              <img src={Locate} className="card-image" />
      </div> 
      <div className='gaping'>
      <Cyprinciple/>
      </div>
      </div>
  )
}

export default Contact
