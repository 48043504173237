import React from 'react'
import './Blogs.css'
import Title from '../Title/Title'
import Cyprinciple from '../Cyprinciple/Cyprinciple'

const Blogs = () => {
  return (
    <div className='bllog'>
    <Title subtitle='Read Our Blogs' title ='Our Latest Blogs'/>
     <div className='blogs'>
      
      <div className='blogs-content'>
        <div className='blogs-title'>
         <bounce><h1> Soon...</h1></bounce>
           </div>
             <div className='blogs-desc'>
               <bounce> <p>To be posted soon</p></bounce>
                <button className='btne'> Read More</button>
               </div>
             </div>
             <div className='blogs-content'>
        <div className='blogs-title'>
         <h1> Soon...</h1>
           </div>
             <div className='blogs-desc'>
                 <p>To be Posted soon</p>
                 <button className='btne'> Read More</button>
               </div>
             </div>
             <div className='blogs-content'>
        <div className='blogs-title'>
         <h1> Soon... </h1>
           </div>
             <div className='blogs-desc'>
                <p>To be posted soon</p>
                <button className='btne'> Read More</button>
               </div>
             </div>
       </div>
         
      <div className='gaping'>
      <Cyprinciple/>
      </div>
       </div>
 )     
}

export default Blogs
