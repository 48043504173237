import React from 'react'
import './About.css'
import Title from '../Title/Title'
import Cyprinciple from '../Cyprinciple/Cyprinciple'

const About = () => {
  return (
   <>
   
    <Title title ='About GcyTech'/>
    <div className='about'>
      <div className='about-right'>
       
        <p> Welcome to <span className="green-text">GcyTech,</span> where we are dedicated to safeguarding your digital assets and ensuring robust security for your organization. 
          With a mission to deliver cutting-edge cyber security solutions, we leverage advanced technology and industry expertise to protect against 
          evolving threats. </p> 
        <h1>Our Mission: </h1>
        <p> To consult on comprehensive and effective cyber security solutions that empower organizations to defend against cyber threats, ensuring their
        operations remain secure and resilient.</p> 
        <h1>Our Team:  </h1>
        <p> Our team comprises experienced professionals with backgrounds in network security, risk management, incident response, and compliance. 
          We bring together a diverse set of skills to address the complex challenges of today’s cyber landscape.</p>
        </div>
       <div className='Our-values'>
       <h3>Our Values: </h3>
        <h2> <span className="darkblu-text">Integrity:</span> Upholding the highest standards of ethics and transparency.</h2> 
        <h2><span className="darkblu-text">Innovation:</span> Continuously advancing our technology, Services and methodologies.</h2>
        <h2> <span className="darkblu-text">Customer Focus:</span> Prioritizing our clients’ needs and delivering tailored solutions.</h2>
      </div>
    </div>
     <div className='gaping'>
    <Cyprinciple/>
    </div>
    </>
  )
}

export default About
