import React from 'react'
import Slider from 'react-slick'
import './Hero.css'

const Hero = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop of images
    speed: 500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Speed of autoplay (ms)
    responsive:[{breakpoint:780, 
    settings: {arrows: false, dots: true,  },},],
  };

  return (
    <div className="hero  contain">
      <div className='hero-slide'>
      <Slider {...settings}>
        
        <div className="hero-slide1">
          <h2>Help Your Employees Understand Their Roles In Securing Your Organization</h2>
          </div>
        <div className="hero-slide3">
          <h2>Build a Zero Trust Cyber Security Architecture</h2>
         </div>
        <div className="hero-slide2">
          <h2>Cyber Security Is Integral Part of Any Business Strategy</h2>
           </div>
           <div className="hero-slide2">
          <h2>Cyber Security Is Shared Responsibility</h2>
           </div>
           
      </Slider>
      </div>
      </div>
    
  );
};

export default Hero;
