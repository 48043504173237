
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebook, faLinkedin, faTwitter, faTelegram} from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import './Footer.css'; // Import the CSS for styling

const Footer = () => {
  const currentYear = new Date().getFullYear();
    return (
       <div className='contain'>
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-column">
                    <h4>About Us</h4>
                    <p>we are dedicated to safeguarding your digital assets and ensuring robust security for your organization. 
                        With a mission to deliver cutting-edge cyber security solutions, 
                        we leverage advanced technology and industry expertise to protect against evolving threats.</p>
                </div>
                <div className="footer-column">
                    <h4>Services</h4>
                    <ul>
                        <li><a href="https://gcytech.com/Training">Training and AWareness</a></li>
                        {/*<li><a href="https://gcytech.com/">Consultancy</a></li>*/}
                        <li><a href="https://gcytech.com/Compliance">Compliance Assessment </a></li>
                        <li><a href="https://gcytech.com/Assessment">Security Assessment</a></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h4>Contact Us</h4>
                    <p>Location: Addis Ababa, Gulele</p>
                    <p>Email: gcytech@gcytech.com</p>
                    <p>Phone: +251 927160562</p>
                </div>
                <div className="footer-column">
                    <h4>Follow Us</h4>
                    <ul>
                    <li><a href="#"> <FontAwesomeIcon icon={faFacebook}/>Facebook</a></li>
                    <li><a href="#"> <FontAwesomeIcon icon={faTwitter}/> Twitter</a></li>
                    <li><a href="#"><FontAwesomeIcon icon={faTelegram}/>Telegram</a></li>
                     <li><a href="#"><FontAwesomeIcon icon={faLinkedin}/>Linkedin</a></li>
                    </ul>
                </div>
               </div>
               <div className="footer-copy">
               <p>&copy; {currentYear} GcyTech. All rights reserved.</p>
              
                </div>
        </footer>
        </div>
    );
}

export default Footer;
