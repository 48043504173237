import React from 'react'
import Title from '../Title/Title'
import assessment from '../../Assets/Assessment2.png'
import   './Assessment.css'
import Cyprinciple from '../Cyprinciple/Cyprinciple'

const Assessment =()=> {

    return (
     
      <div className='contain'>            
     <Title subtitle='Our Service  ' title ='Cyber Security Assessment'/>
     <div className='assessment contain '>    
         <div className='assessment-col'>
            <h3>Cyber Security Assessment </h3>
            <p> We help organizations by simulating real world attack to identify weaknesses in systems, networks, or applications that may be exploited by attackers. We performs the security assessment in a risk based 
            approach to proritize risks and mitigate them in a minimal investment for security controls. <br/>The security assessment produces a detailed report that includes findings about how attackers can gain unauthorized access, along with recommendations for improving security. </p>
                          
        </div>
              <div><img src={assessment} className="card-image" /></div>
                            
      </div> 
         
      <div className='gaping'>
      <Cyprinciple/>
      </div>
      </div>
  )
}

export default Assessment
