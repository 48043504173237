
import Footer from './Components/Footer/Footer'
import React, { useEffect, useState } from 'react'
import logo from './Assets/logo2.png'
import menu_item from './Assets/menu-icon.png'
import { Route, Routes, Link, BrowserRouter as Router} from 'react-router-dom'
import Hero from './Components/Hero/Hero'
import About from './Components/About/About'
import Blogs from './Components/Blogs/Blogs'
import Contact from './Components/Contact/Contact'
import Navbar from './Components/Navbar/Navbar'
import Projects from './Components/Projects/Projects'
import Compliance from './Components/Compliance/Compliance'
import Training from './Components/Trainings/Training'
import './Components/Navbar/Navbar.css'
import Assessment from './Components/Assessment/Assessment'
import NotFound from './Components/NotFound';


function App() {
  const [sticky, setsticky] = useState(false);
    useEffect(() => {window.addEventListener ('scroll', () => {window.scrollY > 50 ? setsticky(true): setsticky(false);
        
    })},[]);
    const [mobilemenu, setmobilemenu] = useState(false);
    const toggleMenu = ()=>{
      mobilemenu ? setmobilemenu(false) : setmobilemenu(true);
    }
  return (
      <div className='contain'>
      
      <Router>
        <Hero/>
        <nav className={`contain ${sticky? 'nav-dark': ''}`}>
         <div className='logodiv'>
        <img src={logo} alt='' className='logo'></img>
        <h1> GcyTech IT Solutions plc</h1>
         </div>
       
            <ul className={mobilemenu ?'': 'mobile-hid'}>
               <li> <Link to='/'> Home</Link></li>
               <li> <Link to='/About'> About</Link></li>
               <li className="dropdown">
                <Link to='/'> Services</Link>
                <div className="dropdown-menu ">
                 <li  className="dropdown-link"><Link to='/Training'> Training and Awareness</Link></li>
                 {/*<li className="dropdown-link"><a href='#!'> Consultancy</a></li>*/}
                 <li className="dropdown-link"><Link to='/Assessment'> Security Assessment</Link></li>
                 <li className="dropdown-link"><Link to ='/Compliance'> Compliance Assessment</Link></li>
             </div>
            </li> 
                <li> <Link to='/Projects'> Projects</Link></li>
                <li> <Link to='/Blogs'> Blogs</Link></li>
                <li> <Link to='/Contact'> Contact Us</Link></li>
                <li> <Link to='/'> Login</Link></li>
                             
            </ul>
            <img src={menu_item} alt='' className='menutem' onClick={toggleMenu}></img>
            </nav>
      <Routes>
        <Route path="/" element={<Navbar/>}/>
        <Route path="/About" element={<About/>} />
         <Route path="/Blogs" element={<Blogs/>} />
         <Route path="/Contact" element={<Contact/> }/>
         <Route path="/Projects" element={<Projects/> }/>
         <Route path="/Compliance" element={<Compliance/> }/>
         <Route path="/Training" element={<Training/> }/>
         <Route path="/Assessment" element={<Assessment/> }/>
         <Route path="*" element={<NotFound />} />
       </Routes>
       <Footer/>
       
    </Router>
    </div>
   
   );  
  
}

export default App




