import React from 'react'
import Title from '../Title/Title'
import Compimage from '../../Assets/Compliance.png'
import   './Compliance.css'
import Cyprinciple from '../Cyprinciple/Cyprinciple';

const Compliance =()=> {

    return (
      <div className='contain'>            
       <Title subtitle='Our Service  ' title ='Cyber Security Compliance Assessment'/>
       <div className='compliance'>    
         <div className='compliance-col'>
            <h3>Corporate Compliance Assessment </h3>
            <p> We Assess your practices and culture of your 
                implementation of your organizational cyber secuirty policies, procedures 
                and other frameworks. Do you have approved cyber secuirty management systems? Are they implemented well?
                Are all employees aware of them? Is your infrastracture comply with your organizational security management system? 
                Are your corporate policy, Issue spesific policy, System spesific policy and procedures alive? We help organizations 
                establish strong and living cyber secuirty governance and management system</p>
           
              <h3>International Compliance Assessment </h3>
              <p> We help organizations mandated to comply with international standards such as SWIFT, ISO 27001, PCIS DSS and others. 
                 </p>
                 <h3>SWIFT Compliance Assessment</h3>
                 <p>  SWIFT developed the Customer Security Program (CSP) to promote cyber security within the SWIFT user community and to drive collaboration in the battle against cyber security threats. SWIFT prepared customer Security Controls Frameworks (CSCF) consists of both mandatory and advisory security controls. Users are mandated to annually assess their SWIFT infrastructure against the CSCF by an independent assessor. Conducting a comprehensive assessment improves users’ SWIFT Customer Security Program (CSP) compliance. Ensuring the security and integrity of SWIFT environment is critical to users, and a thorough assessment is essential to identify any potential implementation gaps, improve the users’ security posture, and maintain the trust of stakeholders and partners. <br />To achieve this, we conduct a comprehensive assessment of your organization's SWIFT Customer Security Program (CSP) compliance. Our team has required industrial certificates (CISM, CISSP, ISO27001 lead auditor, CEH) and experiences neccessary to execute the independent assessment</p>
              </div>
              
              <div className='compimage'>
               <img src={Compimage} className="card-image" /></div>
              <div className='right-col'>
                 <h4>ISO 27001 </h4>
                 <p> ISO/IEC 27001 is a widely recognized standard for information security management systems (ISMS). Achieving ISO 27001 compliance requires organizations to establish, implement, maintain, and continually improve an ISMS to ensure that sensitive data is protected. A 27001 compliance assessment involves evaluating an organization's adherence to the requirements set out in the ISO 27001 standard. <br />This assessment generally follows a structured approach to review policies, procedures, controls, and other aspects of the ISMS</p>
             </div>
          
      </div> 
       <div className='gaping2'>
        <Cyprinciple/> 
       </div>
      </div>
  )
}

export default Compliance
