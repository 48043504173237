import React from 'react';

function NotFound() {
  return (
    <div style={{ textAlign: 'center', marginTop: '150px',  marginBottom: '150px' }}>
      
      <h1>Sorry, the page you are looking for does not exist.</h1>
    </div>
  );
}

export default NotFound;
