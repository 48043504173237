import React from 'react'
import './Projects.css'
import Cyprinciple from '../Cyprinciple/Cyprinciple';
const Projects= ()=> {
 
      return (
            <>
     <div className='proj contain'>
           <h1> COMING SOON</h1>
           
      </div>
      <div className='gaping'>
      <Cyprinciple/>
      </div>
      </>
   );  
  
}

export default Projects